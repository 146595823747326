var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-spin',{attrs:{"spinning":_vm.is_loading}},[_c('div',{class:_vm.$style['order-invoice'],attrs:{"id":"order-invoice"}},[_c('section',{class:_vm.$style['section']},[_c('h2',{attrs:{"id":"subject"}},[_vm._v("进货管理")]),_c('a-form',{class:_vm.$style['filter-form'],attrs:{"layout":"inline","form":_vm.searchForm},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{class:_vm.$style['filter-item'],attrs:{"colon":false,"label":"进货单号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code',{
              initialValue: _vm.seach.code,
            }]),expression:"['code',{\n              initialValue: seach.code,\n            }]"}],class:_vm.$style['normal-input'],attrs:{"allowClear":"","placeholder":"请输入进货单号"}})],1),_c('a-form-item',{class:_vm.$style['filter-item'],attrs:{"colon":false,"label":"进货门店"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['warehouse_id',{
              initialValue: _vm.seach.warehouse_id,
            }]),expression:"['warehouse_id',{\n              initialValue: seach.warehouse_id,\n            }]"}],attrs:{"showSearch":"","allow-celar":"","option-filter-prop":"children","filter-option":_vm.filterOption,"placeholder":"请输入进货门店"}},_vm._l((_vm.repo_data.detail),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.repo_id}},[_vm._v(" "+_vm._s(item.repo_name)+" ")])}),1)],1),_c('a-form-item',{class:_vm.$style['filter-item'],attrs:{"colon":false,"label":"发货工厂"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['factory_id',{
              initialValue: _vm.seach.factory_id,
            }]),expression:"['factory_id',{\n              initialValue: seach.factory_id,\n            }]"}]},_vm._l((_vm.goodsRejectedList),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('a-form-item',{class:_vm.$style['filter-item'],attrs:{"colon":false,"label":"状态"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['status',{
              initialValue: _vm.seach.status,
            }]),expression:"['status',{\n              initialValue: seach.status,\n            }]"}]},_vm._l((_vm.statusType),function(item,index){return _c('a-select-option',{key:index,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.value)+" ")])}),1)],1),_c('a-form-item',{class:_vm.$style['filter-item'],attrs:{"colon":false,"label":"下单人"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['creator_name',{
              initialValue: _vm.seach.creator_name,
            }]),expression:"['creator_name',{\n              initialValue: seach.creator_name,\n            }]"}],class:_vm.$style['normal-input'],attrs:{"allowClear":"","placeholder":"请输入下单人"}})],1),_c('a-form-item',{class:_vm.$style['filter-item'],attrs:{"colon":false,"label":"要求到货日期"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['expect_arrival_time',{
              initialValue: _vm.seach.expect_arrival_time,
            }]),expression:"['expect_arrival_time',{\n              initialValue: seach.expect_arrival_time,\n            }]"}],attrs:{"placeholder":['开始时间', '结束时间'],"allowClear":"","format":"YYYY-MM-DD HH:mm:ss","show-time":{
              hideDisabledOptions: true,
              defaultValue: [_vm.moment('00:00:00', 'HH:mm:ss'), _vm.moment('11:59:59', 'HH:mm:ss')],
            }}})],1),_c('a-form-item',{class:_vm.$style['filter-item'],attrs:{"colon":false,"label":"下单时间"}},[_c('a-range-picker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['create_time',{
              initialValue: _vm.seach.create_time,
            }]),expression:"['create_time',{\n              initialValue: seach.create_time,\n            }]"}],attrs:{"placeholder":['开始时间', '结束时间'],"allowClear":"","format":"YYYY-MM-DD HH:mm:ss","show-time":{
              hideDisabledOptions: true,
              defaultValue: [_vm.moment('00:00:00', 'HH:mm:ss'), _vm.moment('11:59:59', 'HH:mm:ss')],
            }}})],1),_c('br'),_c('a-button',{class:_vm.$style['search-btn'],attrs:{"type":"primary","html-type":"submit"},on:{"click":_vm.query}},[_vm._v(" 搜索 ")]),_c('a-button',{attrs:{"type":"link"},on:{"click":_vm.resetFilter}},[_vm._v("清空搜索条件")])],1)],1),_c('section',{class:_vm.$style['section']},[_c('a-table',{attrs:{"pagination":_vm.paginationOption(_vm.purchase_data),"data-source":_vm.purchase_data.detail,"rowKey":"id","tableLayout":"fixed","rowClassName":(record, index) => (index && index % 2 === 1 ? 'row-background' : '')}},[_c('a-table-column',{attrs:{"title":"进货单号","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"进货门店","data-index":"warehouse_name"}}),_c('a-table-column',{attrs:{"title":"发货工厂","data-index":"factory_name"}}),_c('a-table-column',{attrs:{"title":"要求到货日期","data-index":"expect_arrival_time"}}),_c('a-table-column',{attrs:{"title":"状态","data-index":"status"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(_vm._s(text == 1?'待接单':'已接单'))]}}])}),_c('a-table-column',{attrs:{"title":"下单人","data-index":"creator_name"}}),_c('a-table-column',{attrs:{"title":"下单时间","data-index":"create_time"}}),_c('a-table-column',{attrs:{"title":"操作","width":"5%","data-index":"id"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('p',{class:_vm.$style['operation'],on:{"click":function($event){return _vm.oldSearchData(`/factory/purchaseDetail?id=${text}&page=${_vm.next_page}`)}}},[_vm._v(" 详情")])]}}])})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }