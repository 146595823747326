
import { Component, Vue } from 'vue-property-decorator';
import { changeLoading } from '@/util/decorators';
import moment from 'moment';
import { useMain } from '@/pinia/index'
import { Form as aForm } from 'ant-design-vue';
// api
import { queryPurchase, queryRepoAndPaginate, queryFactory } from '@/api/factory/purchase'

// 组件
import CustomModal from '@/component/custom-modal.vue';
const STATUS_TYPE = [
  {
    id: 0,
    value: '全部',
  },
  {
    id: 1,
    value: '待接单',
  },
  {
    id: 2,
    value: '已接单',
  },
]

@Component({
  name:'FactoryPurchase',
    components: {
        CustomModal,
    },
})
export default class FactoryPurchase extends Vue{
  searchForm: any
  pagination = useMain().pagination
  moment: any = moment;
  is_loading: boolean = false;
  next_page: number = 1;
  goodsRejectedList: any = [];
  purchase_data: any = {
    current: 1
  }
  repo_data: any = {
    current: 1
  }

  get statusType() {
    return STATUS_TYPE;
  }


  seach: any = {
      code: '',
      warehouse_id: '',
      creator_name: '',
      status: 0,
      factory_id: 0,
      expect_arrival_time: [],
      create_time: []
  }
  handleSubmit(e){
    e.preventDefault();
    this.searchForm.validateFields(async (err, values) => {
      if (!err) {
        this.seach = {
          ...this.seach,
          ...values,
        }
        await this.fetchSeachList();
      }
    });
    return false
  }
  // 获取产品列表
  async fetchList(current: number = 1) {
      this.purchase_data = await queryPurchase(current);
  }
  async repoList(current: number = 1) {
      this.repo_data = await queryRepoAndPaginate(current);
  }
  // 搜索
  async query() {
      await this.fetchSeachList();
  }
  @changeLoading(['is_loading'])
  async fetchSeachList(current: number = 1) {
      const send_data: any = {
          code: this.seach.code,
          warehouse_id: this.seach.warehouse_id,
          creator_name: this.seach.creator_name,
          status: this.seach.status,
          factory_id: this.seach.factory_id,
          expect_arrival_time_start: this.seach.expect_arrival_time[0] ? this.seach.expect_arrival_time[0].format('X') : '',
          expect_arrival_time_end: this.seach.expect_arrival_time[1] ? this.seach.expect_arrival_time[1].format('X') : '',
          time_start: this.seach.create_time[0] ? this.seach.create_time[0].format('X') : '',
          time_end: this.seach.create_time[1] ? this.seach.create_time[1].format('X') : '',
      };
      for (const key in send_data) {
      if (send_data[key]==='') delete send_data[key];
      }
      this.purchase_data = await queryPurchase(current, send_data);
  }

  // 搜索清空重置
  async resetFilter() {
      this.seach = {
        code: '',
        warehouse_id: '',
        creator_name: '',
        status: 0,
        factory_id: 0,
        expect_arrival_time: [],
        create_time: []
      };
      this.searchForm.resetFields()
      await this.fetchList();
  }

  onTableChange(pagination: any) {
    this.next_page = pagination.current;
    this.fetchSeachList(this.next_page);
  }

  //分页
  paginationOption(data: any) {
    return {
      current: +data.current || 0,
      total: data.total || 0,
      pageSize: data.limit || 0,
      showTotal: () => `共${data.total}条，每页${data.limit}条`,
    };
  }
  
  filterOption(input: any, option: any) {
    return (
      option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    );
  }

  oldSearchData(path: string) {
    this.pagination.product = {
      ...this.seach,
      current: this.purchase_data.current
    }
    this.$router.push(path)
  }

  @changeLoading(['is_loading'])
  async created() {
      this.searchForm = (aForm as any)?.createForm(this, {
        name: 'form',
      });
      await this.repoList(1);
      this.goodsRejectedList = await queryFactory()
      this.goodsRejectedList.unshift({
        id: 0,
        name: '全部'
      })
      if (this.pagination?.product) {
        Object.keys(this.pagination.product).forEach((item) => {
          if (item !== 'current') {
            this.seach[item] = this.pagination.product[item]
          }
        })
        await this.fetchSeachList(this.pagination.product.current);
        delete this.pagination.product
        return
      }
      await this.fetchList(this.$route.query.page ? +this.$route.query.page : 1);
  }

}
